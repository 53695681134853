<template>
  <v-card elevation="0" outlined class="company-card-scroll" @click="showDetailsModelCompany">

    <v-card-subtitle class="pb-0" :class="company.category.iconColor+'--text'">
      <v-icon :color="company.category.iconColor" class="mb-1">mdi-{{ company.category.icon }}</v-icon>
      {{ company.category.name }}
    </v-card-subtitle>

    <v-card-title class="py-0">
      <!-- <v-icon small class="mr-1 ml-1 mb-1">mdi-circle</v-icon> -->
      <b class="">{{ company.name }}</b>
    </v-card-title>

    <v-divider class="mt-2"/>

    <!-- <v-card-text v-if="small">
      {{ company.description.substr(0, 100) }}
      {{ company.description.length > 100 ? '...' : '' }}
    </v-card-text> -->

    <!-- NOT SMALL -->
    <template v-if="!small">

      <v-card-text>{{ company.description }}</v-card-text>

      <v-divider/>

      <v-row class="mx-0 py-3">
        <v-col cols="12" sm="6" :title="company.surfaceMax > 1000 ? '1ha = 10.000m2' : ''">
          <small>
            <v-icon small class="mb-1">mdi-texture-box</v-icon> 
            Surface d'une parcelle
            <b class="ml-1" v-if="company.surfaceParcelle > 1000">{{ company.surfaceParcelle / 10000 }} ha</b>
            <b v-else>{{ company.surfaceParcelle }} m2</b>
          </small><br>
          <small>
            <v-icon small class="mb-1">mdi-texture-box</v-icon> 
            Surface minimum
            <b class="ml-1" v-if="company.surfaceMin > 1000">{{ company.surfaceMin / 10000 }} ha</b>
            <b v-else>{{ company.surfaceMin }} m2</b>
          </small><br>
          <small>
            <v-icon small class="mb-1">mdi-texture-box</v-icon> 
            Surface maximum
            <b class="ml-1" v-if="company.surfaceMax > 1000">{{ company.surfaceMax / 10000 }} ha</b>
            <b v-else>{{ company.surfaceMax }} m2</b>
          </small>
        </v-col>
        <v-col cols="12" sm="5" class="text-left mx-auto">
          <small>
            <v-icon small class="mb-1">mdi-cash</v-icon> Prix <b>{{ company.surfacePrice }} òto /m2</b>
          </small><br>
          <small>
            <v-icon small class="mb-1">mdi-cash</v-icon> Prix min <b>{{ company.surfacePrice * company.surfaceMin }} òto</b>
          </small><br>
          <small>
            <v-icon small class="mb-1">mdi-cash</v-icon> Prix max <b>{{ company.surfacePrice * company.surfaceMax }} òto</b>
          </small>
        </v-col>

      </v-row>

      <v-divider/>

      <template v-if="company.productions.length == 0">
        <v-card-subtitle class="pb-2">
          <v-icon small>mdi-chevron-down</v-icon> Produits autorisés à la vente
          <br>
          <small class="ml-5">Liste des produits dont vous pouvez faire le commerce avec ce type d'entreprise</small>
          <template v-if="company.productsEnabled.length == 0">
            <br><b class="ml-5"><v-icon class="mb-1" small>mdi-cancel</v-icon> Aucun</b>
          </template>
        </v-card-subtitle>
        <v-row class="mx-0 py-4 px-1">
          <template v-for="(prod) in company.productsEnabled">
            <v-chip outlined :key="prod._id+'-serv'"  
                    :color="prod.iconColor"
                    v-if="prod != null"
                    class="ma-1 pr-4">
              <v-icon :color="prod.iconColor" class="mr-1">
                mdi-{{ prod.icon }}
              </v-icon>
              {{ prod.name }}
            </v-chip>
          </template>
        </v-row>
      </template>
      <template v-else>
        <v-card-subtitle class="pb-2">
          <v-icon small>mdi-chevron-down</v-icon> Entrées
          <br>
          <small class="ml-5">Liste des produits consommés pour produire</small>
          <template v-if="productsInLength == 0">
            <br><b class="ml-5"><v-icon class="mb-1" small>mdi-cancel</v-icon> Aucun</b>
          </template>
        </v-card-subtitle>
        <v-row class="mx-0 py-4 px-1">
          <template v-for="(production) in company.productions">
            <template v-for="(prod) in production.productsIn">
              <v-chip outlined :key="prod._id+production._id"  
                      :color="prod.iconColor"
                      v-if="prod != null"
                      class="ma-1 pr-4">
                <v-icon :color="prod.iconColor" class="mr-1">
                  mdi-{{ prod.icon }}
                </v-icon>
                {{ prod.name }}
              </v-chip>
            </template>
          </template>
        </v-row>

        <v-divider/>
        
        <v-card-subtitle class="pb-0">
          <v-icon small>mdi-chevron-down</v-icon> Sorties
          <br>
          <small class="ml-5">Liste des produits qui peuvent être fabriqués</small>
          <template v-if="company.productions.length == 0">
            <br><b class="ml-5"><v-icon class="mb-1" small>mdi-cancel</v-icon> Aucun</b>
          </template>
        </v-card-subtitle>
        <v-row class="mx-0 py-5 px-3">
          <template v-for="(production) in company.productions">
              <v-chip outlined :key="production._id+'-out'"  
                    :color="production.productOut.iconColor"
                      v-if="production.productOut != null"
                      class="ma-1 pr-4">
                <v-icon :color="production.productOut.iconColor" class="mr-1">
                  mdi-{{ production.productOut.icon }}
                </v-icon>
                {{ production.productOut.name }}
              </v-chip>
          </template>
        </v-row>

      </template>

      <v-divider/>
      <v-row>
        <v-col cols="12" class="pa-4">
          <template v-for="(score, keyS) in servicesScoresShrink">
            <div class="d-inline-block pa-1" :key="keyS">
              <v-chip small outlined class="px-2" :title="score.scoreModel.name">
              <b>{{ score.scoreBonus >= 0 ? "+" : "-" }}</b>
              <v-icon small class="ml-2">mdi-{{ score.scoreModel.icon }}</v-icon>
              <span v-if="!small" class="ml-1">{{ score.scoreModel.name }}</span>
              </v-chip>
            </div>
          </template>
        </v-col>
      </v-row>

    </template>

    <!--  SMALL -->
    <template v-else>

    <v-row class="mx-0 py-1">
            
      <v-col cols="12" class="pt-3 px-2">
        <template v-for="(score, keyS) in servicesScoresShrink">
          <div class="d-inline-block pa-1" :key="keyS+'shrink'">
            <v-chip small outlined class="px-2" :title="score.scoreModel.name">
            <b>{{ score.scoreBonus >= 0 ? "+" : "-" }}</b>
            <v-icon small class="ml-2">mdi-{{ score.scoreModel.icon }}</v-icon>
            <span v-if="!small" class="ml-1">{{ score.scoreModel.name }}</span>
            </v-chip>
          </div>
        </template>
        <template v-for="(production, keyP) in company.productions">
          <v-icon :color="production.productOut.iconColor" 
                  :title="production.productOut.name"
                  class="ma-1 mx-0" :key="keyP"
                  v-if="keyP < 38" :small="company.productions.length > 5">
            mdi-{{ production.productOut.icon }}
          </v-icon>
        </template>
        <template v-for="(prod, keyP2) in company.productsEnabled">
            <v-icon :key="keyP2" :color="prod.iconColor" class="ma-1 mx-0">
              mdi-{{ prod.icon }}
            </v-icon>
          </template>
        <!-- {{ company.productions.length >= 8 ? "..." : "" }} -->
      </v-col>

    </v-row>

    </template>

    


    <!-- <v-row class="ma-0">
      <v-col cols="6" class="pa-1">
        <v-btn v-if="small" block elevation="0" 
              @click="$root.$emit('showDetailsModelCompany', company)">
          <v-icon class="mr-1">mdi-eye</v-icon>
          Détails
        </v-btn>
      </v-col>

      <v-col cols="6" class="pa-1 pl-0">
        <v-btn v-if="small" block elevation="0" outlined color="green"
              @click="nextStepForm">
          <v-icon class="mr-1">mdi-check</v-icon>
          Choisir
        </v-btn>
      </v-col>
    </v-row> -->
    <!-- <v-btn v-if="!small" block >
      
      <v-btn block >
        Créer mon entreprise !
      </v-btn>
    </v-btn> -->

  </v-card>
</template>

<style>
  .theme--light .company-card-scroll:hover{
    background-color:rgb(236, 236, 236);
  }
  .theme--dark .company-card-scroll:hover{
    background-color:rgb(37, 37, 37);
  }
</style>

<script>

//import axios from 'axios'
//import router from '../router/router'
//import core from '../plugins/core.js'

//import Post from '@/components/Post.vue'

export default {
  name: 'empty',
  props: ['company', 'small'],
  components: {  },
  data: () => ({
    
    
  }),
  async mounted(){
    
  },
  methods: {
    showDetailsModelCompany: function(){
      if(this.small)
        this.$root.$emit('showDetailsModelCompany', this.company)
    },

    nextStepForm(){
      this.$store.dispatch("company/saveFirstParamsForm", 
                            { companyName: '', //this.newCompanyName, 
                              companyModel: this.company })

      this.$router.push('/create-company-form')
    }
  },
  computed: {
    productsInLength: function(){
      let length = 0
      this.company.productions.forEach((production) => {
        production.productsIn.forEach(() => {
          length++
        })
      })
      return length
    },

    servicesScoresShrink: function(){
      let memScoreName = []
      let shrinkScores = []

      this.company.productions.forEach((production) => {
        production.productOut.services.forEach((service) => {
            if(memScoreName[service.scoreModel.name] == null){
              shrinkScores.push({ scoreModel: service.scoreModel, scoreBonus: service.scoreBonus })
              memScoreName[service.scoreModel.name] = service.scoreModel.name
            }
        })
      })
      if(this.company.productions.length == 0)
      this.company.productsEnabled.forEach((product) => {
        product.services.forEach((service) => {
            if(memScoreName[service.scoreModel.name] == null){
              shrinkScores.push({ scoreModel: service.scoreModel, scoreBonus: service.scoreBonus })
              memScoreName[service.scoreModel.name] = service.scoreModel.name
            }
        })
      })
      return shrinkScores
    },

    cardHeight: function(){
      let height = this.$vuetify.breakpoint.height
      console.log("height", height)
      height = height > 0 ? height : 500

      let coeff = this.$vuetify.breakpoint.width > 960 ? 250 : 136

      let dialogHeight = height
      let el = document.getElementById("dialogCompanyCard")
      dialogHeight = el ? (height-coeff) : 50

      console.log("dialogHeight", height, dialogHeight)
      if(!this.small) return dialogHeight
      else            return null
    }
  }
}
</script>
