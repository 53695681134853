<template>
<div>

  <v-col cols="12" md="10" class="mx-auto">
    <v-card elevation="0">

      <v-card-title>
        <v-icon large>mdi-package-variant-plus</v-icon>
        Créer votre entreprise
      </v-card-title>

      <v-divider/>

      <v-col cols="12" sm="6">
      <v-card-text>
        Pour créer votre entreprise, sélectionnez le type d'entreprise que vous souhaitez créer, 
        en fonction des caractéristiques proposées par chaque activité.
      </v-card-text>
      </v-col>

      <v-row class="mx-0 px-1">
        <template v-for="(company, cKey) in $store.state.company.companyModels">
          <v-col cols="12" sm="6" md="6" lg="4" class="px-2" :key="cKey">
            <CompanyCard :company="company" :small="true"/>
          </v-col>
        </template>
      </v-row>

    </v-card>
  </v-col>

  <v-dialog  :fullscreen="$vuetify.breakpoint.width < 960"
              v-model="showDialogDetails" max-width="800" scrollable
    >
    <v-card id="dialogCaptcha">
      <v-toolbar elevation="0">
        <v-icon class="mr-1">mdi-package-variant-plus</v-icon> Créer votre entreprise

        <v-spacer/>
        <v-btn @click="showDialogDetails = false" 
                outlined icon small class="ml-5 mr-0">
                <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      

      <v-card-text class="pt-5">
          <CompanyCard :company="companySelected" :small="false"/>
      </v-card-text>

      <v-toolbar>
        <v-spacer/>
        <v-btn outlined @click="nextStepForm" color="green" elevation="0">
          <v-icon class="mr-1" small>mdi-check</v-icon> Choisir ce type d'entreprise 
          <!-- <v-icon class="ml-1">mdi-arrow-right-circle</v-icon>  -->
        </v-btn>
      </v-toolbar>

    </v-card>
  </v-dialog>
  
</div>
</template>

<style></style>

<script>

//import axios from 'axios'
//import router from '../router/router'
//import core from '../plugins/core.js'

import CompanyCard from '@/components/company/CompanyCard.vue'

export default {
  name: 'empty',
  components: { CompanyCard },
  data: () => ({
    companySelected: null,
    showDialogDetails: false,
    newCompanyName: ""
  }),
  async mounted(){
    this.$store.dispatch("company/fetchCompanyModels")

    this.$root.$off('showDetailsModelCompany')
              .$on('showDetailsModelCompany', (company) => { 
        //console.log("rerenderChartWallet")
        this.companySelected = company
        this.showDialogDetails = true
    })
  },
  methods: {
    nextStepForm(){
      this.$store.dispatch("company/saveFirstParamsForm", 
                            { companyName: this.newCompanyName, 
                              companyModel: this.companySelected })

      this.$router.push('/create-company-form')
    }
  },
  computed: {
    
    
  }
}
</script>
